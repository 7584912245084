import { GET_OIDC_PROVIDER } from 'constants/actionTypes';

export const getOidcProvider = (
  { issuer, name },
  { onSuccess, onFailure },
) => ({
  type: GET_OIDC_PROVIDER,
  payload: { issuer, name },
  onSuccess,
  onFailure,
});
