import { push as redirectTo } from 'connected-react-router';
import { isNil } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Text } from '@summer/ui-components';

import { postOAuthCode } from 'redux/actions/oauth.actions';
import { getOidcProvider } from 'redux/actions/oidc.actions';

const baseUrl = import.meta.env.REACT_APP_API_BASE_URL;
const legacyOauthProviders = ['google', 'facebook', 'cityOfAlexandria'];

const OAuthCallback = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { provider } = useParams();

  const onSuccess = useCallback(
    ({ name }) => {
      const search = new URLSearchParams(location.search);
      const url = new URL(`${baseUrl}/v1/oauth/oidc/${name}?${search}`);
      window.location.href = url.toString();
    },
    [location.search],
  );

  useEffect(() => {
    // TODO remove in ck code cleanup
    const search = new URLSearchParams(location.search);

    if (provider === 'creditkarma' && isNil(search.get('state'))) {
      dispatch(redirectTo('/onboard/creditkarma/oauth'));
    } else if (legacyOauthProviders.includes(provider)) {
      dispatch(postOAuthCode(provider, search));
    } else {
      dispatch(getOidcProvider({ name: provider }, { onSuccess }));
    }
  }, [dispatch, provider, location.search, onSuccess]);
  return <Text>Authenticating...</Text>;
};

export default OAuthCallback;
