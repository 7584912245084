import { push as redirectTo } from 'connected-react-router';

import {
  GET_OIDC_PROVIDER,
  NETWORK_SUCCESS,
  NETWORK_FAILURE,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import handleSideEffects from 'redux/handleSideEffects';

const oidcMiddleware = handleSideEffects({
  [GET_OIDC_PROVIDER]: (dispatch, { payload, onSuccess, onFailure }) => {
    dispatch(
      apiRequest({
        url: '/v1/oauth/oidc',
        method: 'GET',
        fromAction: GET_OIDC_PROVIDER,
        params: payload,
        meta: { onSuccess, onFailure },
      }),
    );
  },
  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === GET_OIDC_PROVIDER && meta.onSuccess) {
      meta.onSuccess(payload.response);
    }
  },
  [NETWORK_FAILURE]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === GET_OIDC_PROVIDER) {
      if (meta.onFailure) {
        meta.onFailure(payload.response);
      } else if (payload.error.status === 404) {
        dispatch(redirectTo('/login'));
      }
    }
  },
});

export default oidcMiddleware;
