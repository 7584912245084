import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getOidcProvider } from 'redux/actions/oidc.actions';

const baseUrl = import.meta.env.REACT_APP_API_BASE_URL;

const OAuthIDPInitiated = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const search = new URLSearchParams(location.search);
  const iss = search.get('iss');

  const onSuccess = useCallback(({ name }) => {
    const url = new URL(`${baseUrl}/v1/oauth/oidc/${name}`);

    window.location.href = url.toString();
  }, []);

  useEffect(() => {
    dispatch(getOidcProvider({ issuer: iss }, { onSuccess }));
  }, [dispatch, iss, onSuccess]);

  return <div>Authenticating...</div>;
};

export default OAuthIDPInitiated;
